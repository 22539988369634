/** @jsx jsx */
import Layout from "../components/layout"
import {
  jsx,
  Box,
  Button,
} from 'theme-ui'

export default () => (
  <Layout title="Test">

    <div sx={{
      fontFamily: 'monospace',
      color: 'primary',
    }}>
      <p sx={{ 
         lineHeight: 'copy',
         fontStyle: 'italic',
         // how to add custom media queries if nec
         '@media (min-width: 420px)': {
            fontSize: 3,
          }
      }}>
        Frontend web-developer who was into the JAM stack before it had a name.  Wordpress hater. Tailwind lover. If it's Emacs or Vim, I'll take Vim.
      </p>
    </div>

    <Box sx={{ m: 4 }}>
      <Button>
        Go
      </Button>
    </Box>

    <p sx={{ color: 'secondary',
             fontWeight: 'bold',
              }}>Secondary Color</p>

    <p sx={{ color: 'accent' }}>Accent</p>

    <p sx={{ backgroundColor: 'highlight' }}>highlight</p>

    <p sx={{ color: 'muted' }}>mutedcolor</p>

    <p sx={{ color: 'whatever' }}>Burritovore.</p>


  </Layout>
)

